export default {
   branches: {
      allBranches: [],
      loading: false,
      data: [],
      pagination: {
         tokenPages: [],
         totalPages: 0,
         currentPage: 1,
      }
   }
}
