import {db} from "../../../../../plugins/firebase";
import axiosClient from "@/axios";

const routeAuthenticated = '/auth/branches';
const branchesRef = db.collection('branches');

const getAll = async () => {
   const branches = await branchesRef.get();

   const results = [];
   branches.forEach((doc) => {
      let item = doc.data();
      results.push(item);
   });

   return results;
}

const getAllPaginated = async (pageSize = 10, pageToken = '') => {
   return await axiosClient.get(`${routeAuthenticated}/get-all-paginated?pageSize=${pageSize}&pageToken=${pageToken}`);
}

const getPaginationInfo = async (pageSize) => {
   return await axiosClient.get(`${routeAuthenticated}/get-pagination-info?pageSize=${pageSize}`)
}

const get = async uuid => {
   return await axiosClient.get(`${routeAuthenticated}/get/${uuid}`);
}

const save = async (type, branch) => {
   if (type === 'create')
      return await axiosClient.post(`${routeAuthenticated}/create`, branch);
   else
      return await axiosClient.put(`${routeAuthenticated}/edit/${branch.uuid}`, branch);
}

const search = async (name) => {
   const branches = await branchesRef.get();

   const results = [];
   branches.forEach((doc) => {
      let item = doc.data();

      if (item.name) {
         const branchNormalized = item.name.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase();
         const searchNormalized = name.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase();

         if (branchNormalized.includes(searchNormalized))
            results.push(item);
      }
   });

   return results;
}

const destroy = async uuid => {
   return await axiosClient.delete(`${routeAuthenticated}/delete/${uuid}`);
}

export default {
   getAll,
   getAllPaginated,
   get,
   save,
   search,
   destroy,
   getPaginationInfo
}
