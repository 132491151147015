import { getCurrentDateTime } from "../../../../../utils/date-times";

export default {
   SET_QUESTION_PAGINATION_INFO: (state, info) => {
      state.questions.pagination.totalPages = info.totalPages
      state.questions.pagination.tokenPages = info.tokenPages
   },

   SET_QUESTIONS: (state, questions) => {
      state.questions.data = questions;
   },

   SET_QUESTIONS_LOADING: (state, status) => {
      state.questions.loading = status;
   },

   SET_CURRENT_QUESTION: (state, question) => {
      state.currentQuestion.data = question;
   },

   SET_CURRENT_QUESTION_LOADING: (state, status) => {
      state.currentQuestion.loading = status;
   },

   SET_CURRENT_QUESTION_ANSWER: (state, newAnswer) => {
      if (!state.currentQuestion.data.alreadyAnswered) {
         state.currentQuestion.data.alreadyAnswered = true;
         state.currentQuestion.data.answeredAt = getCurrentDateTime();
      }

      state.currentQuestion.data.answers = [newAnswer];
   },

   RESET_CURRENT_QUESTION: (state) => {
      state.currentQuestion.data = {};
   },

   SET_QUESTION_CURRENT_PAGE: (state, currentPage) => {
      state.questions.pagination.currentPage = currentPage
   }
};
