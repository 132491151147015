
const extractYearAndMonth = (dateString) => {
   const parts = dateString.split(/[-\s:]/);

   const year = parseInt(parts[0], 10);
   const month = parseInt(parts[1], 10);

   return { year, month };
}

const getMonthName = (monthNumber) => {
   const months = [
      'Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho',
      'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'
   ];

   if (monthNumber >= 1 && monthNumber <= 12) {
      return months[monthNumber - 1];
   } else {
      return 'Número do mês inválido';
   }
}

const getOldestDate = (dates) => {
   const oldestDate = new Date(Math.min(...dates.map(data => new Date(data))));

   return oldestDate;
}

const getOldestDateButTheSameYear = (dates, year) => {
   const datesOfYear = dates.filter(date => new Date(date).getFullYear() == year);
   const oldestDate = new Date(Math.min(...datesOfYear.map(data => new Date(data))));

   return oldestDate;
}

const getLatestDate = (dates) => {
   const latestDate = new Date(Math.max(...dates.map(data => new Date(data))));

   return latestDate;
}

const getLatestDateButTheSameYear = (dates, year) => {
   const datesOfYear = dates.filter(date => new Date(date).getFullYear() == year);
   const latestDate = new Date(Math.max(...datesOfYear.map(data => new Date(data))));

   return latestDate;
}

const getFourPreviousMonths = (month)  => {
   const months = [];

   for (let i = 1; i <= 4; i++) {
      let previousMonth = month - i;

      if (previousMonth < 1) {
         previousMonth += 12;
      }

      months.unshift(previousMonth);
   }

   return months;
}

const getTheRightPlanId = (plans, paymentDate)  => {
   let planId = null;

   //ordering for the certain that the latest date came in first
   plans = plans.sort((a, b) => b.includedAt - a.includedAt);

   for (let i = 0; i < plans.length; i++) {
      const plan = plans[i];

      if (plan.includedAt <= paymentDate){
         planId = plan.planId;
         break;
      }
   }

   return planId;
}

export {
   extractYearAndMonth,
   getMonthName,
   getOldestDate,
   getOldestDateButTheSameYear,
   getLatestDate,
   getLatestDateButTheSameYear,
   getTheRightPlanId,
   getFourPreviousMonths,
}
