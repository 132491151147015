import axiosClient from '@/axios'
import {db} from "../../../../../plugins/firebase";

const routeAuthenticated = '/auth/partner-companies';
const partnerCompaniesRef = db.collection('partnerCompanies');
const partnerCompaniesBenefitsRelationRef = db.collection('partnerCompaniesBenefitsRelation');

const getAllPaginated = async (pageSize = 10, pageToken = '') => {
   return await axiosClient.get(`${routeAuthenticated}/get-all-paginated?pageSize=${pageSize}&pageToken=${pageToken}`);
}

const getPaginationInfo = async (pageSize) => {
   return await axiosClient.get(`${routeAuthenticated}/get-pagination-info?pageSize=${pageSize}`)
}

const getAllPartnerCompaniesBenefitsRelation = async () => {
   const partnerCompaniesRelation = await partnerCompaniesBenefitsRelationRef.get();

   const results = [];
   partnerCompaniesRelation.forEach((doc) => {
      let item = doc.data();

      results.push(item);
   });

   return results;
}

const get = async uuid => {
   return await axiosClient.get(`${routeAuthenticated}/get/${uuid}`);
}

const save = async (type, partnerCompany) => {
   if (type === 'create')
      return await axiosClient.post(`${routeAuthenticated}/create`, partnerCompany);
   else{
      if (partnerCompany.imageCoverUrlToDelete !== null) {
         const regex = /imageCover%2F([^.]+)\.([a-zA-Z]+)\?/;
         const imageCoverString = partnerCompany.imageCoverUrlToDelete.match(regex);

         let imageCoverNameToDelete = null;
         let extension = null;
         if (imageCoverString && imageCoverString[1] && imageCoverString[2]) {
            extension = imageCoverString[2];
            imageCoverNameToDelete = imageCoverString[1] + `.${extension}`;
         }
         partnerCompany.imageCoverUrlToDelete = imageCoverNameToDelete;
      }
      if (partnerCompany.presentationImagesUrlToDelete.length > 0) {
         const regex = /presentationImages%2F([^.]+)\.([a-zA-Z]+)\?/;
         partnerCompany.presentationImagesUrlToDelete = partnerCompany.presentationImagesUrlToDelete.map((url) => {

            const presentationImgUrlString = url.match(regex);

            let presentationImgToDelete = null;
            let extension = null;
            if (presentationImgUrlString && presentationImgUrlString[1] && presentationImgUrlString[2]) {
               extension = presentationImgUrlString[2];
               presentationImgToDelete = presentationImgUrlString[1] + `.${extension}`;
               return presentationImgToDelete;
            }
         });
      }

      return await axiosClient.put(`${routeAuthenticated}/edit/${partnerCompany.uuid}`, partnerCompany);
   }
}

const search = async (name) => {
   const partnerCompanies = await partnerCompaniesRef.get();

   const results = [];
   partnerCompanies.forEach((doc) => {
      let item = doc.data();

      if (item.name) {
         const partnerCompanyNormalized = item.name.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase();
         const searchNormalized = name.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase();

         if (partnerCompanyNormalized.includes(searchNormalized))
            results.push(item);
      }
   });

   return results;
}

const destroy = async (uuid, allBenefits, allPartnerCompaniesBenefitsRelation) => {
   const data = {
      allBenefits,
      allPartnerCompaniesRelation: allPartnerCompaniesBenefitsRelation
   };
   return await axiosClient.delete(`${routeAuthenticated}/delete/${uuid}`, { data: data });
}

export default {
   getAllPaginated,
   get,
   save,
   search,
   destroy,
   getPaginationInfo,
   getAllPartnerCompaniesBenefitsRelation
}
