import BranchService from "../services/branch-service";

export default {
   getAllBranches: async ({commit}) => {
      try {
         const response = await BranchService.getAll();
         commit('SET_ALL_BRANCHES', response)

      } catch (e) {
         console.log('Error getAllBranches: ', e)
      } finally {
      }
   },

   getAllPaginatedBranches: async ({commit}, { pageSize = 10, pageToken = ''} = {}) => {
      commit('SET_BRANCH_LOADING', true)

      try {
         const response = await BranchService.getAllPaginated(pageSize, pageToken);
         commit('SET_BRANCHES', response.data)

      } catch (e) {
         console.log('Error getBranches: ', e)
      } finally {
         commit('SET_BRANCH_LOADING', false)
      }
   },

   getInfoPaginationBranches: async ({commit}, pageSize = 10) => {
      const { data: paginationInfo } = await BranchService.getPaginationInfo(pageSize);
      commit('SET_BRANCH_PAGINATION_INFO', { totalPages: paginationInfo.totalPages, tokenPages: paginationInfo.tokens })
   },

   searchBranches: async ({commit}, name) => {
      commit('SET_BRANCH_LOADING', true)
      try {
         const results = await BranchService.search(name);
         let response = {
            data: results
         };

         commit('SET_BRANCHES', response)
         return response
      } finally {
         commit('SET_BRANCH_LOADING', false)
      }
   }
}
