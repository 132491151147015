import articleService from "../services/article-service";

export default {
   getArticleInfoPagination: async ({commit}, pageSize = 10) => {
      const { data: paginationInfo } = await articleService.getPaginationInfo(pageSize);
      commit('SET_ARTICLE_PAGINATION_INFO', { totalPages: paginationInfo.totalPages, data: paginationInfo.data, tokenPages: paginationInfo.tokens })
   },

   getAllArticlesCategoriesRelation: async ({commit}) => {
      const allArticlesCategoriesRelation = await articleService.getAllArticlesCategoriesRelation();
      commit('SET_ALL_ARTICLES_CATEGORIES_RELATION', allArticlesCategoriesRelation);
   }
}
