function getCurrentDateTime() {
   const now = new Date();
   const year = now.getFullYear();
   const month = (now.getMonth() + 1).toString().padStart(2, "0");
   const day = now.getDate().toString().padStart(2, "0");
   const hours = now.getHours().toString().padStart(2, "0");
   const minutes = now.getMinutes().toString().padStart(2, "0");
   const seconds = now.getSeconds().toString().padStart(2, "0");

   return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
}

function formatDate(date) {
   const [datePart, timePart] = date.split('T');
   const [year, month, day] = datePart.split('-');
   const [hour, minutes] = timePart.split(':');

   const dayNumber = day;
   const monthNumber = month;
   const yearNumber = year;
   const hourNumber = hour;
   const minutesNumber = minutes;

   return `${dayNumber}/${monthNumber}/${yearNumber} ${hourNumber}:${minutesNumber}`;
}

export {
   getCurrentDateTime,
   formatDate
}
