export default {
   SET_ARTICLE_PAGINATION_INFO: (state, info) => {
      state.articles.pagination.totalPages = info.totalPages
      state.articles.pagination.data = info.data
      state.articles.pagination.tokenPages = info.tokenPages
   },

   SET_ARTICLE_CURRENT_PAGE: (state, currentPage) => {
      state.articles.pagination.currentPage = currentPage
   },

   SET_ALL_ARTICLES_CATEGORIES_RELATION: (state, allArticlesCategoriesRelation) => {
      state.articles.allArticlesCategoriesRelation = allArticlesCategoriesRelation
   }
}
