export default {
   articles: {
      allArticlesCategoriesRelation: [],
      pagination: {
         currentPage: 1,
         data: [],
         tokenPages: [],
         totalPages: 0,
      }
   }
}
