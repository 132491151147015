export default {
   SET_ALL_BRANCHES: (state, branches) => {
      state.branches.allBranches = branches;
   },

   SET_BRANCH_PAGINATION_INFO: (state, info) => {
      state.branches.pagination.totalPages = info.totalPages
      state.branches.pagination.tokenPages = info.tokenPages
   },

   SET_BRANCHES: (state, branches) => {
      state.branches.data = branches.data;
   },

   SET_BRANCH_CURRENT_PAGE: (state, currentPage) => {
      state.branches.pagination.currentPage = currentPage
   },

   SET_BRANCH_LOADING: (state, loading) => {
      state.branches.loading = loading
   }
}
