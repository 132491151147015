const Branches = () => import('../views/Branches.vue')
const BranchesForm = () => import('../views/BranchesForm.vue')

export default [
   {
      path: 'branches',
      name: 'branches',
      component: Branches
   },
   {
      path: 'branches/create',
      name: 'branchesCreate',
      component: BranchesForm
   },
   {
      path: 'branches/:uuid',
      name: 'branchesEdit',
      component: BranchesForm
   }
]
