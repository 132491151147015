export default {
   reports: {
      getTableInfo: [],
      tableLabels: [],
      tableData: [],
      totalMonthlyRent: 0,
      totalClients: 0,
      totalComments: 0,
      totalSignatures: 0,
      totalSignaturesByMonth: [],
      qtySignaturesByMonth: [],
      loadingInformation: false
   }
};
